<template>
  <div>
     <div id="mobile_menu" :class="[collapsed ? '' : 'visible-sm visible-xs']" class="responsive_menu"  >

        <ul class="main_menu">
            <li><a href="/">Startseite</a></li>
            <li><a href="/veranstaltungen">Veranstaltungen</a></li>

            		<li><a href="contact">Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
                <li class="active"><a href="datenschutz">Datenschutz</a></li>

        </ul> <!-- /.main_menu -->
    </div> <!-- /.responsive_menu -->

  <header class="site-header clearfix">
    <div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="logo">
            <!-- <div class="pull-leftqualibi_logo"> -->
              <a href="/"><img class="pull-left qualibi_logo_img" src="../../src/assets/logo.png"></a>
            <!-- </div> -->
					</div>	<!-- /.logo -->
					<div class="main-navigation pull-right">
						<nav class="main-nav visible-md visible-lg">
							<ul class="sf-menu">
								<li><a href="/">Startseite</a></li>
                <li><a href="/veranstaltungen">Veranstaltungen</a></li>

                <li><a href="contact">Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
                <li  class="active"><a href="datenschutz">Datenschutz</a></li>
							</ul> <!-- /.sf-menu -->
						</nav> <!-- /.main-nav -->

							<!-- This one in here is responsive menu for tablet and mobiles -->
					    <div class="responsive-navigation visible-sm visible-xs">
					        <a @click=" collapsed = !collapsed" href="#" class="menu-toggle-btn">
                      <i :class="[collapsed ? 'fa-bars' : 'fa-times fa-lg', 'fa']"></i>
					        </a>
					    </div> <!-- /responsive_navigation -->

					</div> <!-- /.main-navigation -->

				</div> <!-- /.col-md-12 -->

			</div> <!-- /.row -->

		</div> <!-- /.container -->
	</header> <!-- /.site-header -->
  <section id="datenschutz-heading" class="cta clearfix">
		<div class="container">
		<div class="row">
				<div class="col-md-12">
						<div class="section-header">
						<h1 class="h1-headings purple-text">Datenschutz</h1>
					</div> <!-- /.section-header -->
				</div>
			</div>
		</div>
	</section>
  <section id="datenschutz">
    <div class="container">
			<div class="row">
        <div class="col-md-12 text-left">
          <h2>Datenschutzerklärung gemäß Datenschutzgrundverordnung (DSGVO) und § 13 Telemediengesetz (TMG)</h2>
          <h3>1. Geltungsbereich</h3>
          <p>Diese Datenschutzerklärung gilt für das Internet-Angebot QualiBi – Daten der Qualitativen Bildungsforschung unter  <b><i><a style="color:#42b983;" href="https://www.qualibi.net">https://www.qualibi.net</a></i></b> und für die über diese Internetseiten erhobenen personenbezogenen Daten. Für Internetseiten anderer Anbieter, auf die z.B. über Links verwiesen wird, gelten die dortigen Datenschutzhinweise und ‑erklärungen.</p>
          <h3>2. Betreiber des Internetangebotes</h3>
          <p>Dieses Angebot wird vom DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation in Kooperation mit der Goethe-Universität Frankfurt betrieben. Das DIPF ist verantwortlich für die Verarbeitung der auf diesen Seiten erhobenen personenbezogenen Daten. Anschrift und Kontaktdaten entnehmen Sie bitte dem Impressum <b><i><a style="color:#42b983;" href="https://www.qualibi.net/impressum">(https://www.qualibi.net/impressum)</a></i></b>.</p>
          <h3>3. Zuständige Datenschutzbeauftragte</h3>
          <p>Wenn Sie Fragen zum Thema Datenschutz bei QualiBi haben, wenden Sie sich an unsere Datenschutzbeauftragte. Sie können dort erfragen, welche Ihrer Daten bei uns gespeichert sind. Darüber hinaus können Sie Anfragen für Auskünfte, Löschungs- und Berichtigungswünsche zu Ihren Daten und gerne auch Anregungen jederzeit per E-Mail oder Brief an folgende Adresse senden:</p>
          <i>gds Gesellschaft für Datenschutz Mittelhessen mbH</i><br>
          <i>Frau Viktorija Meinel (Ass. jur.)</i><br>
          <i>Auf der Appeling 8</i><br>
          <i>35043 Marburg a.d. Lahn</i><br>
          <i style="color:#42b983;">E-Mail: meinel@gdsm.de oder an datenschutz@dipf.de</i>
          <h3>4. Rechtsgrundlage der Datenverarbeitung und Umgang mit Ihren Daten</h3>
          <p>Die auf dieser Webseite erhobenen Daten dienen dem DIPF dazu, die angebotenen Dienste zu erbringen und den Nutzern dieser Webseiten, diese Dienste in Anspruch zu nehmen.</p>
          <p>Rechtsgrundlage für die – z.T. vorübergehende - Speicherung der Daten und der Logfiles ist Art. 6 Abs. 1 lit. a und f DSGVO. Personenbezogene Daten, die wir zur Erfüllung bestimmter Dienstleistungen erheben, erhalten wir aufgrund Ihrer Einwilligung in die Erhebung, Speicherung und Verarbeitung dieser Daten laut Nutzungsordnung und gemäß Art. 6 Abs. 1 lit. a DSGVO.</p>

          <p>Die auf dieser Webseite erhobenen personenbezogenen Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</p>
          <h3>5. Art der erhobenen Daten</h3>
          <h4>a) Nutzungsdaten</h4>
          Bei jeder Anforderung einer Internetdatei werden folgende nicht personenbeziehbare Zugriffsdaten beim DIPF gespeichert:
          <ul>
          <li>	die Seite, von der aus die Datei angefordert wurde,</li>
          <li>	der Name der aufgerufenen Datei,</li>
          <li>	das Datum und die Uhrzeit der Anforderung,</li>
          <li>	die übertragene Datenmenge,</li>
          <li>	der Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.),</li>
          <li>	die Zugriffsart (GET, POST),</li>
          <li>	den verwendeten Browser bzw. das verwendeten Betriebssystem,</li>
          <li>	die Session-ID</li>
          </ul>
          <h4>b) Personenbezogene Daten</h4>
          Zu den auf dieser Website erhobenen personenbezogenen Daten gehören:
          <ul>
          <li>	IP-Adresse</li>
          <li>	Cookies

              <ul>
              <li>o	PIWIK/Matomo Web Analytics (nähere Informationen und Erfassung ausschalten)</li>
              </ul>
          </li>
          <li>	Im Zusammenhang mit einem Nutzerkonto: Name, Anschrift, E-Mail-Adresse, Telefon, Arbeitsplatz/Institution, berufliche Position, ggf. wissenschaftlicher Betreuer</li>
          </ul>

          <h4>6. Dauer der Speicherung</h4>
          <p>Einige der auf diesem Angebot von Ihnen erhobenen Daten werden nur kurzfristig oder zur Erbringung eines angeforderten Dienstes gespeichert (IP-Adresse). Aus Gründen der Datensicherheit, also um unerlaubte Zugriffe aufklären oder Missbrauch der Internetseite verhindern zu können, wird die vollständige IP-Adresse des anfordernden Rechners erfasst, gespeichert und sieben Tage nach dem Ende des Zugriffs anonymisiert. Personenbezogene Daten in Zusammenhang mit einem Nutzerkonto beim FDZ Bildung werden für die Dauer des Bestehens des Kontos gespeichert. Mit dem Löschen des Nutzerkontos werden die für statistische Zwecke relevanten Angaben in anonymisierter Form weiter gespeichert. Bitte beachten Sie auch unsere Nutzungsbedingungen, denen Sie vor Nutzung des Dienstes zugestimmt haben.</p>
          <h4>7. Ihre Rechte</h4>
          Sie haben als Nutzende dieses Internetangebotes gemäß DSGVO Art. 15-18 und Art. 20-21 die folgenden Rechte:
          <div v-for="(Rechte,index) in IhreRechte" :key='index'>
            <h4>{{Rechte.heading}}</h4>
            <p>{{Rechte.text}}</p>
              <ul>
                <li v-if="Rechte.list1">{{Rechte.list1}}</li>
                <li v-if="Rechte.list2">{{Rechte.list2}}</li>
              </ul>
            <p v-if="Rechte.extra">{{Rechte.extra}}</p>
            </div>
          <h4>8. Hinweis</h4>
          <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
          <h4>9. Erklärungen zu Funktionen der Webanalyse</h4>
          <h5>Erläuterungen zu Piwik/Matomo Web Analytics</h5>
          <p>Unsere Website verwendet Matomo (ehemals Piwik), dabei handelt es sich um einen sogenannten Webanalysedienst. Matomo verwendet sog. "Cookies", das sind Textdateien, die auf Ihrem Computer gespeichert werden und die unsererseits eine Analyse der Benutzung der Webseite ermöglichen. Zu diesem Zweck werden die durch den Cookie erzeugten Nutzungsinformationen (einschließlich Ihrer gekürzten IP-Adresse) an unseren Server übertragen und zu Nutzungsanalysezwecken gespeichert, was der Webseitenoptimierung unsererseits dient. Ihre IP-Adresse wird bei diesem Vorgang umgehend anonymisiert, so dass Sie als Nutzer für uns anonym bleiben. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden nicht an Dritte weitergegeben. Sie können die Verwendung der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern, es kann jedoch sein, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können.</p>
          <p>Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch nicht einverstanden sind, dann können Sie der Speicherung und Nutzung nachfolgend per Mausklick jederzeit widersprechen. In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten erhebt. Achtung: Wenn Sie Ihre Cookies löschen, so hat dies zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und gegebenenfalls von Ihnen erneut aktiviert werden muss.</p>

</div> <!-- /.col-md-12 -->
      </div>
		</div>
	</section>

	<footer class="site-footer footer-design">
    <div class="container">
			<div class="row">
				<div class="col-md-12">
					<nav class="footer-nav clearfix">
						<ul class="footer-menu">
								<li><a href="/">Startseite</a></li>
                <li><a href="/veranstaltungen">Veranstaltungen</a></li>
                <li><a href="contact">Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
                <li  class="active"><a href="datenschutz">Datenschutz</a></li>
						</ul> <!-- /.footer-menu -->
					</nav> <!-- /.footer-nav -->
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
			<div class="row">
				<div class="col-md-12">
					<p class="copyright-text">Copyright &copy; DIPF</p>
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
		</div> <!-- /.container -->
	</footer> <!-- /.site-footer -->
  <ScrollTopArrow></ScrollTopArrow>
  </div>

</template>

<script>
import ScrollTopArrow from '@/components/ScrollTopArrow'
export default {
  name: 'DatenSchutz',
   components: {
    ScrollTopArrow
  },
  data(){
    return {
      collapsed: true,
			IhreRechte: [
				{heading:"7.1 Recht auf Auskunft", text: "Sie können Auskunft gem. Art. 15 DSGVO oder § 52 HDSIG über Ihre von uns verarbeiteten personenbezogenen Daten verlangen. In Ihrem Auskunftsantrag sollten Sie Ihr Anliegen präzisieren, um uns das Zusammenstellen der erforderlichen Daten zu erleichtern. Bitte beachten Sie, dass Ihr Auskunftsrecht durch die Vorschriften der §§ 24 Abs. 2, 25 Abs. 2, 26 Abs. 2 und 33 HDSIG § 52 Abs. 2 bis 5 HDSIG eingeschränkt wird.", list1:"", list2:"", extra:""},
				{heading:"7.2 Recht auf Berichtigung", text: "Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, können Sie nach Art. 16 DSGVO oder § 53 HDSIG eine Berichtigung verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine Vervollständigung verlangen.", list1:"", list2:"", extra:""},
        {heading:"7.3 Recht auf Löschung", text: "Sie können unter den Bedingungen des Art. 17 DSGVO und der §§ 34 und 53 HDSIG die Löschung Ihrer personenbezogenen Daten verlangen.", list1:"", list2:"", extra:""},
        {heading:"7.4 Recht auf Einschränkung der Verarbeitung", text: "Sie haben im Rahmen der Vorgaben des Art. 18 DSGVO oder § 53 HDSIG das Recht, eine Einschränkung der Verarbeitung der Sie betreffenden Daten zu verlangen.", list1:"", list2:"", extra:""},
        {heading:"7.5 Recht auf Datenübertragbarkeit",text: "Sie haben nach Art. 20 DSGVO das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns zur Verfügung bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern", list1:"I.	die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und", list2:"II.	die Verarbeitung mithilfe automatisierter Verfahren erfolgt.", extra:"In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden."},
        {heading:"7.6 Recht auf Widerspruch", text: "Sie haben nach Art. 21 DSGVO das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit der Verarbeitung der Sie betreffenden Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f erfolgt, zu widersprechen.", list1:"", list2:"", extra:""},
        {heading:"7.7 Recht auf Beschwerde", text: "Wenn Sie der Auffassung sind, dass wir bei der Verarbeitung Ihrer Daten datenschutzrechtliche Vorschriften nicht beachtet haben, können Sie sich mit einer Beschwerde an die behördliche Datenschutzbeauftragte des Hessischen Beauftragten für Datenschutz und Informationsfreiheit wenden, die Ihre Beschwerde prüfen wird.", list1:"", list2:"", extra:""},
			]
		}
  }
}
</script>
<style scoped>
.text-left{
  text-align: left;
}
 #datenschutz-heading{
  margin-top:10vh;
}
</style>
