<template>
  <div>
    <div id="mobile_menu" :class="[collapsed ? '' : 'visible-sm visible-xs']" class="responsive_menu"  >

        <ul class="main_menu">
            <li><a href="/">Startseite</a></li>
						<li><a href="/veranstaltungen">Veranstaltungen</a></li>

            		<li><a href="contact">Kontakt</a></li>
                <li class="active"><a href="impressum">Impressum</a></li>
                <li><a href="datenschutz">Datenschutz</a></li>

        </ul> <!-- /.main_menu -->
    </div> <!-- /.responsive_menu -->

  <header class="site-header clearfix">
    <div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="logo">
            <!-- <div class="pull-leftqualibi_logo"> -->
              <a href="/"><img class="pull-left qualibi_logo_img" src="../../src/assets/logo.png"></a>
            <!-- </div> -->
					</div>	<!-- /.logo -->
					<div class="main-navigation pull-right">
						<nav class="main-nav visible-md visible-lg">
							<ul class="sf-menu">
								<li><a href="/">Startseite</a></li>
								<li><a href="/veranstaltungen">Veranstaltungen</a></li>

                <li><a href="contact">Kontakt</a></li>
                <li class="active"><a href="impressum">Impressum</a></li>
                <li><a href="datenschutz">Datenschutz</a></li>
							</ul> <!-- /.sf-menu -->
						</nav> <!-- /.main-nav -->

							<!-- This one in here is responsive menu for tablet and mobiles -->
					    <div class="responsive-navigation visible-sm visible-xs">
					        <a @click=" collapsed = !collapsed" href="#" class="menu-toggle-btn">
                      <i :class="[collapsed ? 'fa-bars' : 'fa-times fa-lg', 'fa']"></i>
					        </a>
					    </div> <!-- /responsive_navigation -->

					</div> <!-- /.main-navigation -->

				</div> <!-- /.col-md-12 -->

			</div> <!-- /.row -->

		</div> <!-- /.container -->
	</header> <!-- /.site-header -->
  <section  id="impressum-heading" class="cta clearfix">
		<div class="container">
		<div class="row">
				<div class="col-md-12">
						<div class="section-header">
						<h1 class="h1-headings purple-text">Impressum</h1>
					</div> <!-- /.section-header -->
				</div>
			</div>
		</div>
	</section>
  <section id="impressum">
    <div class="container">
			<div class="row">
        <div class="col-md-12">
							<div class="impressum-wrapper">
								<h1 style="padding-bottom:15px">	Anbieter dieser Internetpräsenz</h1>
								<div class="address-text ">

								<span style="color: #6670EB; font-family:Raleway" >DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation</span><br>
								Rostocker Straße 6<br>
								D-60323 Frankfurt am Main<br>
								Tel. +49 (0) 69 24708-0<br>
								Fax +49 (0) 69 24708-444<br>
								E-Mail: kontakt@qualibi.net<br>
								Internet: <a style="color:#42b983;" href="https://www.qualibi.net" target="_blank">www.qualibi.net</a><br>
								Umsatzsteuer-Identifikationsnummer: DE114237569
								</div>
								<p class="section-desc">Das DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation ist eine rechtsfähige Stiftung des Öffentlichen Rechts.
								</p>
								<p class="section-desc">Es unterstützt Forschung, Politik und Praxis im Bildungsbereich durch wissenschaftliche Infrastruktur und vielfältige Forschungstätigkeit. Als Mitglied der Leibniz-Gemeinschaft verbindet das Institut erkenntnisorientierte Grundlagenforschung mit innovativen Entwicklungsarbeiten und Anwendungen zum Nutzen der Gesellschaft und ihrer Mitglieder.</p>
								<h4>Vertretungsberechtigte Personen</h4>
								<div class="person-text ">

								Prof. Dr. Kai Maaz (Geschäftsführender Direktor)<br>
								Prof. Dr. Sabine Reh (Stellvertretende Geschäftsführende Direktorin)<br>
								Susanne Boomkamp-Dahmen (Geschäftsführerin)<br>

								<h4>Koordination</h4>

								Dr. Doris Bambey (Inhaltlich Verantwortlich gem. § 55 Abs. 2 RStV)<br>
								Dr. Christoph Leser (Goethe-Universität)<br>
								Dr. Thomas Lösch (DIPF)<br>

								<h4>Programmierung:</h4>
								Shivantika Thakur
								</div>

								<div class="address-text ">
								<h4>Obere Aufsichtsbehörde:</h4>
								Hessisches Ministerium für Wissenschaft und Kunst (HMWK)<br>
								Rheinstraße 23-25,<br>
								65185 Wiesbaden<br>
								<h4>Untere Aufsichtsbehörde:</h4>
								Regierungspräsidium Darmstadt<br>
								Luisenplatz 2,<br>
								64287 Darmstadt<br><br><br>
								Das RP Darmstadt hat die Aufsicht über das DIPF auf den<br>
								Magistrat der Stadt Frankfurt am Main übertragen.<br>
								Haftungsausschluss<br>
								<h4>Rechtliche Hinweise zur Haftung / Disclaimer</h4>
								</div>
								<p class="section-desc">
								Für Vollständigkeit, Fehler redaktioneller und technischer Art, Auslassungen usw. sowie die Richtigkeit der Eintragungen kann - auch wegen der raschen Veränderungen im Bildungswesen und im Internet - keine Haftung übernommen werden. Insbesondere kann keine Gewähr für die Vollständigkeit und Richtigkeit von Informationen übernommen werden, die über weiterführende Links erreicht werden. Anbieter sind für die eigenen Inhalte, die sie zur Nutzung bereithalten, nach den allgemeinen Gesetzen verantwortlich. Von diesen eigenen Inhalten sind Querverweise auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Die Hyperlinks, die auf fremde Web-Seiten und deren Inhalte verlinken, dienen lediglich der Information. Die Verantwortlichkeit für die Inhalte dieser fremden Web-Seiten liegt alleine bei dem Anbieter, der diese Inhalte bereithält. Der Inhalt der fremden Web-Seiten kann jederzeit ohne Wissen des Instituts geändert worden sein.
								</p>
								<p class="section-desc">
								Für alle Links auf den Seiten der DIPF-Homepage gilt: Wir möchten ausdrücklich betonen, dass wir keinerlei Einfluss auf die Gestaltung und die Inhalte der gelinkten Seiten haben. Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten der auf dieser Homepage gelinkten Seiten und machen uns ihre Inhalte nicht zu Eigen.
								</p>
								<p class="section-desc">
								Diese Erklärung gilt für alle Seiten dieser Homepage und die dort angebrachten Links. Falls die Website des Deutschen Instituts für Internationale Pädagogische Forschung auf Seiten verweist, deren Inhalt Anlass zur Beanstandung gibt, bitten wir ausdrücklich um Mitteilung.
								</p>
            	</div><!-- /.impressum-wrapper -->

				</div> <!-- /.col-md-12 -->
      </div>
		</div>
	</section>

	<footer class="site-footer footer-design">
    <div class="container">
			<div class="row">
				<div class="col-md-12">
					<nav class="footer-nav clearfix">
						<ul class="footer-menu">
								<li><a href="/">Startseite</a></li>
								<li><a href="/veranstaltungen">Veranstaltungen</a></li>
                <li><a href="contact">Kontakt</a></li>
                <li class="active"><a href="impressum">Impressum</a></li>
                <li><a href="datenschutz">Datenschutz</a></li>
						</ul> <!-- /.footer-menu -->
					</nav> <!-- /.footer-nav -->
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
			<div class="row">
				<div class="col-md-12">
					<p class="copyright-text">Copyright &copy; DIPF</p>
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
		</div> <!-- /.container -->
	</footer> <!-- /.site-footer -->
	<ScrollTopArrow></ScrollTopArrow>
  </div>
</template>

<script>
import ScrollTopArrow from '@/components/ScrollTopArrow'
export default {
  name: 'Impressum',
	 components: {
    ScrollTopArrow
  },
  data(){
    return {
      collapsed: true
    }
  },

}
</script>
<style scoped>
.impressum-wrapper{
  text-align: left;
}

#impressum-heading{
  margin-top:10vh;
}
</style>
