<template>
<div>
  <div class="home">
    <div id="mobile_menu" :class="[collapsed ? '' : 'visible-sm visible-xs']" class="responsive_menu"  >
        <ul class="main_menu">
            <li @click="scrollToAnchorPoint('home')" class="active"><a >Startseite</a></li>
            <li @click="scrollToAnchorPoint('about-us')"><a >Über QualiBi</a></li>
            <li @click="scrollToAnchorPoint('AustauschVernetzung')"><a >Austausch & Vernetzung</a></li>
            <li><a href="/veranstaltungen">Veranstaltungen</a></li>
            <!-- <li @click="scrollToAnchorPoint('Veranstaltungen')"><a >Veranstaltungen</a></li> -->
            <li @click="scrollToAnchorPoint('daten-finden')"><a >Daten finden</a></li>
             <li id="sub_menu" class="sub_menu"><a href="#">Kontakt & mehr</a>
            	<ul>
            		<li><a >Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
                <li><a href="datenschutz">Datenschutz</a></li>
            	</ul>
            </li>
        </ul> <!-- /.main_menu -->
    </div> <!-- /.responsive_menu -->
  </div> <!-- /.home -->
  <header class="site-header clearfix">
       <div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="logo">
            <!-- <div class="pull-leftqualibi_logo"> -->
              <a href="/"><img class="pull-left qualibi_logo_img" src="../../src/assets/logo.png"></a>
            <!-- </div> -->
					</div>	<!-- /.logo -->
					<div class="main-navigation pull-right">
						<nav class="main-nav visible-md visible-lg">
							<ul class="sf-menu">
                <li><a @click="scrollToAnchorPoint('home')">Startseite</a></li>
                 <li><a @click="scrollToAnchorPoint('about-us')">Über QualiBi</a></li>
                <li><a @click="scrollToAnchorPoint('AustauschVernetzung')">Austausch & Vernetzung</a></li>
                <li><a href="/veranstaltungen">Veranstaltungen</a></li>
                <!-- <li><a @click="scrollToAnchorPoint('Veranstaltungen')">Veranstaltungen</a></li> -->
                <li><a @click="scrollToAnchorPoint('daten-finden')">Daten finden</a></li>
                <li><a href="contact">Kontakt</a></li>

							</ul> <!-- /.sf-menu -->
						</nav> <!-- /.main-nav -->

						<!-- This one in here is responsive menu for tablet and mobiles -->
					    <div class="responsive-navigation visible-sm visible-xs">
					        <a @click=" collapsed = !collapsed" href="#" class="menu-toggle-btn">
                      <i :class="[collapsed ? 'fa-bars' : 'fa-times fa-lg', 'fa']"></i>
					        </a>
					    </div> <!-- /responsive_navigation -->

					</div> <!-- /.main-navigation -->

				</div> <!-- /.col-md-12 -->


			</div> <!-- /.row -->

		</div> <!-- /.container -->
	</header> <!-- /.site-header -->


<div ref="home" id="home">
<head-content/>
</div>

<div ref="about-us" id="about-us">
<our-team/>
</div>

<div ref="AustauschVernetzung" id="AustauschVernetzung">
            <austausch-vernetzung/>
</div>

<!-- <div ref="Veranstaltungen" id="Veranstaltungen">
            <veranstaltungen/>
</div> -->

<div ref="daten-finden" id="daten-finden">
            <daten-finden/>
</div>


  <!-- <post></post> -->

	<footer class="site-footer footer-design">
    <div class="container">
			<div class="row">
				<div class="col-md-12">
					<nav class="footer-nav clearfix">
						<ul class="footer-menu">
							<li><a @click="scrollToAnchorPoint('home')">Home</a></li>
              <li><a @click="scrollToAnchorPoint('about-us')">Über QualiBi</a></li>
              <li><a @click="scrollToAnchorPoint('AustauschVernetzung')">Austausch und Vernetzung</a></li>
                <!-- <li><a @click="scrollToAnchorPoint('Veranstaltungen')">Veranstaltungen</a></li> -->
                <li><a href="/veranstaltungen">Veranstaltungen</a></li>
                <li><a @click="scrollToAnchorPoint('daten-finden')">Daten finden</a></li>

                <li><a href="contact">Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
                <li><a href="datenschutz">Datenschutz</a></li>
						</ul> <!-- /.footer-menu -->
					</nav> <!-- /.footer-nav -->
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
			<div class="row">
				<div class="col-md-12">
					<p class="copyright-text">Copyright &copy; DIPF</p>
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
		</div> <!-- /.container -->
	</footer> <!-- /.site-footer -->
  <ScrollTopArrow></ScrollTopArrow>
  </div>
</template>

<script>
// @ is an alias to /src

import HeadContent from '@/components/HeadContent.vue'
// import Post from '@/components/Post.vue'
import Veranstaltungen from '@/components/Veranstaltungen.vue'
import DatenFinden from '@/components/DatenFinden.vue'
import AustauschVernetzung from '@/components/AustauschVernetzung.vue'
import OurTeam from '@/components/OurTeam.vue'
import Footer from '@/components/Footer.vue'
import ScrollTopArrow from '@/components/ScrollTopArrow'

export default {
  name: 'Home',
  components: {

    HeadContent,
    // Post,
    Veranstaltungen,
    DatenFinden,
    AustauschVernetzung,
		OurTeam,
    Footer,
    ScrollTopArrow

  },
  data(){
    return {
      collapsed: true
    }
  },
  methods:{
    scrollToAnchorPoint(refName) {
            const el = this.$refs[refName]
            el.scrollIntoView({ behavior: 'smooth'})
            console.log(refName)
        }
    }
}
</script>
<style >
.fa-bars:hover{
  color: #42b983;
}
p{
  text-align: justify;
}

 ul.footer-menu li a:hover{
  color: #42b983;
    text-decoration: underline solid #6670EB !important;

}
.header{
  padding:25%;
}
h1.h1-headings-main{

    display: inline;
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 40px;
    line-height: 60px;
    color: #f3e7e7 !important;
    font-family:  "Inter", sans-serif;
    text-shadow: black;

}
.h1-headings{
	 	margin: 0 0 5px 0;
    display: inline;
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 50px;
    line-height: 80px;
    color: #f7f5f5;
    text-decoration: none;
    font-family:  "Inter", sans-serif;
		/* font-family: "Source Sans Pro", Arial, Tahoma, sans-serif; */
}
img.qualibi_logo_img{
  height: 6%;
  width: 12%;

}
h3.service-title{
    margin: 20px 0 0;
    font-size: 18px;
    font-weight: normal;
    margin: 30px 0 20px 0;
    font-family:  "Inter", sans-serif;
}

.thumb-post, .logo,  .main-navigation{
  display: inline;
}
.thumb-post img {
  padding-top: 50px;
    width: 100%;
    height: auto;
}
#upcoming-events, #austausch-vernetzung{
  padding:20px;
}

.parallax-overlay {
    background-color: rgba(0, 0, 0, 0) !important;
}
html {
  scroll-behavior: smooth;
}

.purple{
	background:#6670EB;
}
.green{
	background:#42b983;
}
.purple-text{
	color: #6670EB;
    font-family: "Inter", sans-serif;
    font-weight: normal;
}
.green-text{
	color: #42b983;
    font-family: "Inter", sans-serif;
    font-weight: normal;
}
.dark-text{
	color: #050505;
    font-family: "Inter", sans-serif;
    font-weight: normal;
}
.big-text{
font-size:25px;
}
.centered-text{
text-align: center !important;
}
p.purple-text{
	  color: #6670EB;
    font-family: "Inter", sans-serif;
    font-weight: normal;
}
p.dark-text{
	text-align:justify;
  font-weight: 500 !important;
}
div.text-box-md-12{
	Padding:20px;
	margin-bottom: 10px;
}
h1.section-title{
  padding:7px;
}
h2.section-title{
  padding:7px;
}
.event-h3{
    margin: 0 0 5px 0;
    display: inline;
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 20px;
    line-height: 27px;
    color: #696464;
    text-decoration: none;
    font-family: "Source Sans Pro", Arial, Tahoma, sans-serif;
}
.section-header{
  margin-top:50px;
}
.dimension{
  padding-top:30px;
}.cta {
    padding: 35px 0;
    border-bottom: 1px solid #ffffff00 !important;
    text-align: center;
}
section#home-head{
  margin-top:60px;
  background:#6670EB;
}

section#veranstaltungen{
  background: rgb(245,244,224);
background: linear-gradient(90deg, rgba(245,244,224,1) 0%, rgba(255,255,255,1) 35%, rgba(245,244,224,1) 100%);
  }
  section.services{
  background: rgb(245,244,224);
background: linear-gradient(90deg, rgba(245,244,224,1) 0%, rgba(255,255,255,1) 35%, rgba(245,244,224,1) 100%);
}
section#austausch-vernetzung, section#uber-uns{
  background: rgb(245,244,224);
background: linear-gradient(90deg, rgba(245,244,224,1) 0%, rgba(255,255,255,1) 35%, rgba(245,244,224,1) 100%);
}
section#daten-nutzen{
  background: rgb(245,244,224);
background: linear-gradient(90deg, rgba(245,244,224,1) 0%, rgba(255,255,255,1) 35%, rgba(245,244,224,1) 100%);
}
footer.footer-design{
background: rgb(245,244,224);
background: linear-gradient(90deg, rgba(245,244,224,1) 0%, rgba(255,255,255,1) 35%, rgba(245,244,224,1) 100%);
}
.section-header-heading {
    padding-bottom: 30px;
    text-align: center;
    margin-top: 10vh;
}
.main_menu li {
    border-bottom: 1px solid #42b983;
    padding: 10px 0;
}
.main-nav ul li a:active {
    color: #81D797;
    background-color: #f1f4f5;
}
/* and (max-width: 660px)*/
@media (max-width: 660px)
{
  h1.h1-headings{
    font-size: 40px;
  }
  p.big-text{
	font-size: 17px;
  }
  h1.section-title{
    font-size: 17px;
  }
  h2.section-title{
    font-size: 15px;
  }
  section#home-head{
  margin-top:0px;
  }
  img.qualibi_logo_img {
    height: 16%;
    width: 32%;
  }
  /* .visible-sm .visible-xs {
    display: block !important;
  } */
  .bottom-right {
    position: fixed;
    bottom: 20px;
    left: 100px;
    cursor: pointer;
}
}
/* @media (max-width: 50px)
{
.visible-sm .visible-xs {
    display: block !important;
  }
} */
/* #veranstaltungen-heading, #austausch-vernetzung-heading, #daten-nutzen-heading, #uber-uns-heading{
  margin-top:15vh;
} */
/* .dark-content {
    background-color: #6670EB;
    width: 100%;
    padding: 70px 0 80px 0;
} */

   /*  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    line-height: 1.5; */
</style>