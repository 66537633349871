<template>
  <div>
  <section id="uber-uns-heading" class="cta clearfix">
		<div class="container">
		<div class="row">
				<div class="col-md-12">
						<div class="section-header-heading">
						<h1 class="h1-headings purple-text">Über QualiBi</h1>

					</div> <!-- /.section-header -->
				</div>
			</div>
		</div>
	</section>

		<section id="uber-uns" class="our-team">
			<div class="container">
				<div class="row">
						<div class="col-md-12">
						<h1 class="section-title">Projektbeschreibung</h1>
					</div> <!-- /.col-md-12 -->

					<div class="col-md-12 ">

							<p class="dark-text">
								Das Projekt QualiBi zielt darauf ab, eine Plattform für Forschungsdaten der qualitativen Bildungsforschung zu schaffen.
								Ziel ist es, Datenbestände der qualitativen Bildungsforschung sowohl zur wissenschaftlichen Nachnutzung als auch für den Einsatz in der Hochschullehre bereitzustellen.
								Dazu werden einerseits bereits vorliegende universitäre wie außeruniversitäre Datenbestände zentral zugänglich gemacht und andererseits ein eigener Archivdatenbestand
								QualiBi sukzessive aufgebaut.
								Die inhaltliche Ausrichtung erstreckt sich auf die ganze Breite qualitativ-empirischer Bildungsforschung.
							</p>
							<p class="dark-text">
								Der Aufbau der Plattform erfolgt in einem prozessorientierten Vorgehen, dessen zentraler Bestandteil ein enger Austausch mit den erziehungswissenschaftlichen Fachcommunities,
								dem Verbund Forschungsdaten Bildung (VerbundFDB) sowie dem Netzwerk <a href="https://www.konsortswd.de/konsortswd/das-konsortium/services/qualidatanet/ " target="_blank">QualiDataNet</a> zu methodologischen und forschungsethischen Fragestellungen ist.
								Damit dient das Projekt auch einem fachkulturellen Austausch über Potenziale und Grenzen einer kooperativen Datennutzung (Data sharing).
								Letztlich werden durch das Projekt konkrete Nutzungsszenarien entwickelt und forschungsethische Fragestellungen bearbeitet,
								um die wissenschaftliche Nutzung von Daten durch Sekundäranalysen auch im Bereich qualitativer Bildungsforschung voranzutreiben.
								Durch die Orientierung an den <a href="https://force11.org/info/the-fair-data-principles/" target="_blank">FAIR-Prinzipien</a> und zielgerichtete Nachhaltigkeitsmaßnahmen
								soll mit QualiBi eine dauerhafte Service- und Contentplattform für die qualitativ forschenden Communities entstehen.
							</p>
							<p class="dark-text">
								Das Projekt QualiBi wird gefördert durch die DFG und durchgeführt in Kooperation zwischen DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation sowie der Goethe-Universität Frankfurt. Die Projektlaufzeit ist von Oktober 2020 bis September 2023.
							</p><br>
						</div>

					<div class="col-md-12">
						<h1 class="section-title">QualiBi Team</h1>
					</div> <!-- /.col-md-12 -->
				</div> <!-- /.row -->
			</div> <!-- /.container -->
			<div class="team-members">
				<div class="container">

					<div class="row">
					<h2 class="section-title">Projektleitung</h2>
						<div v-for="(person,index) in Projektleitung" :key='index'>
							<div class="col-md-3 col-sm-3">
								<div class="team-member">
									<div class="thumb-post">
										<img class="thumb-post-img" :src="require(`@/assets/images/includes/${person.image}`)" alt="">
									</div>
									<div class="member-content">
									<a class="member-name member-link" target="_blank" :href="`${person.link}`">{{person.name}}</a>
										<h4 class="member-role">{{person.university}} <br> {{person.designation}}</h4>
									</div>
								</div> <!-- /.team-member -->
							</div> <!-- /.col-md-3 -->
					</div>
					</div> <!-- /.row -->

						<div class="row">
						<h2 class="section-title">Projektkoordination</h2>
						<div v-for="(person,index) in Projektkoordination" :key='index'>
							<div class="col-md-3 col-sm-3">
								<div class="team-member">
									<div class="thumb-post">
										<img class="thumb-post-img"  :src="require(`@/assets/images/includes/${person.image}`)" alt="">
									</div>
									<div class="member-content">
									<a class="member-name member-link" target="_blank" :href="`${person.link}`">{{person.name}}</a>
										<h4 class="member-role">{{person.university}} <br> {{person.designation}}</h4>
									</div>
								</div> <!-- /.team-member -->
							</div> <!-- /.col-md-3 -->
					</div>
					</div>

					<div class="row">
					<h2 class="section-title">Projektteam</h2>
						<div v-for="(person,index) in Projektteam" :key='index'>
							<div class="col-md-3 col-sm-3">
								<div class="team-member">
									<div v-if="person.image" class="thumb-post">
										<img class="thumb-post-img" :src="require(`@/assets/images/includes/${person.image}`)" alt="">

									</div>

									<div class="member-content">
									<a class="member-name member-link" target="_blank" :href="`${person.link}`">{{person.name}}</a>
										<h4 class="member-role">{{person.university}} <br>{{person.designation}}</h4>
									</div>
								</div> <!-- /.team-member -->
							</div> <!-- /.col-md-3 -->
					</div>
					</div> <!-- /.row -->
					</div>
			</div>
			<div class="kontakt">
				<div class="container">
					<div class="row">
							<div class="col-md-12">
						<h1 class="section-title">Kontakt</h1>
					</div> <!-- /.col-md-12 -->
					<div v-for="(person,index) in ContactDetails" :key='index'>
						<div class="col-md-6 col-sm-6">
							<h6>Kontakt {{person.heading}}</h6>
					<div class="service-box-wrap contact-card-wrap">
						<div class="service-icon-wrap">
							<i class="fa fa-user fa-2x"></i>
							<div id="user-name">
						<h3 class="contact-name">{{person.name}}</h3>
						</div>
						</div> <!-- /.service-icon-wrap -->

						<div class="service-cnt-wrap">
							<div class="role ml-1 text-xl font-light dark-text">
								<span>{{person.designation}}</span>
								</div>
							<div class="text-wrap">
								<div class="contact-details text-xl  dark-text ">
								<div class="text-email-link"><a href="mailto:leser@em.uni-frankfurt.de"><i class="fa fa-envelope-o text-purple-600"></i>{{person.email}}</a></div>
								<div class="text-fa-user-o" ><a target="_blank"  :href="`${person.unilink}`"><i class="fa fa-user text-purple-600"></i>Profil von {{person.name}}</a></div>
								<span class="telephone-number  text-green-400"><i class="fa fa-phone  text-purple-400"></i> {{person.telephone}}</span>
							</div>

							<div class="address-box  dark-text text-xl">
								<div class="address-text ">{{person.address.l1}}<br>{{person.address.l2}}<br>{{person.address.l3}}</div>
								<span class="address-link text-ml"> <a target="_blank"  :href="`${person.webaddress}`"><i class="fa fa-external-link text-purple-600"></i>{{person.webtitle}}</a></span>
							</div>
						</div>

						</div> <!-- /.service-cnt-wrap -->
					</div> <!-- /.service-box-wrap -->
						</div> <!-- /.col-md-6 -->
					</div>

					</div>
			</div>
					</div>
		</section> <!-- /.our-team -->
  </div>
</template>
<script>
export default {
  name: 'OurTeam',
  props: {

  },
	  data(){
    return {
	 Projektkoordination: [
				{name: "Dr. Christoph Leser", university: "Goethe-Universität", designation: "Koordination", image:"member-christoph.jpg", link:"https://www.uni-frankfurt.de/55946073/Dr__phil__Christoph_Leser"},
				{name: "Dr. Thomas Lösch", university: "DIPF", designation: "Koordination", image:"member-thomas.jpg", link:"https://www.dipf.de/de/institut/personen/loesch-thomas"}
				],
 	 Projektleitung: [
	 			{name: "Prof. Dr. Mirja Silkenbeumer", university: "Goethe-Universität", designation: "Projektleitung", image:"member-mirja.jpg", link:"https://www.uni-frankfurt.de/55945511/Prof__Dr__Mirja_Silkenbeumer"},
				{name: "Prof. Dr. Marc Rittberger", university: "DIPF", designation: "Projektleitung", image:"member-marc.jpg", link:"https://www.dipf.de/de/institut/personen/rittberger-marc"},
				{name: "Dr. Doris Bambey", university: "DIPF", designation: "Projektleitung", image:"member-doris.jpg", link:"https://www.dipf.de/de/institut/personen/bambey-doris"}
				],
 	 Projektteam: [
	 			{name: "Tristan Bauder", university: "DIPF", designation: "Wissenschaftlicher Mitarbeiter", image:"member-tristan.jpg", link:"https://www.dipf.de/de/institut/personen/bauder-tristan"},
				{name: "Sinje M. Brinkmann", university: "Goethe-Universität", designation: "Wissenschaftliche Mitarbeiterin", image:"member-brinkmann.jpg", link:"https://www.uni-frankfurt.de/55945971/Sinje_Mareille_Brinkmann__M_A"},
				{name: "Dr. Marius Gerecht", university: "DIPF", designation: "Wissenschaftlicher Mitarbeiter", image:"member-marius.jpg", link:"https://www.dipf.de/de/institut/personen/gerecht-marius"},
				{name: "Dr. Susanne  Klauke", university: "DIPF", designation: "Wissenschaftliche Mitarbeiterin", image:"member-tristan.jpg", link:"https://www.dipf.de/de/institut/personen/klauke-susanne"},
				{name: "Maike Porzelt", university: "DIPF", designation: "Wissenschaftliche Mitarbeiterin", image:"member-maike.jpg", link:"https://www.dipf.de/de/institut/personen/porzelt-maike"},
				{name: "Dr. Kristina Schierbaum", university: "Goethe-Universität", designation: "Wissenschaftliche Mitarbeiterin", image:"member-schierbaum.jpg", link:"https://www.uni-frankfurt.de/55945642/Dr__phil__Kristina_Schierbaum"},
				{name: "Alexander Schuster", university: "DIPF", designation: "Wissenschaftlicher Mitarbeiter", image:"member-alex.jpg", link:"https://www.dipf.de/de/institut/personen/schuster-alexander"},
				{name: "Shivantika Thakur", university: "DIPF", designation: "Entwicklerin", image:"member-shivantika.jpg", link:"https://www.dipf.de/de/institut/personen/thakur-shivantika"},
				],
		ContactDetails: [
				{heading:"an der Goethe-Universität:", name: "Dr. Christoph Leser", designation: "Fachbereich Erziehungswissenschaften", telephone:"+49 (0)69 798 36723", email:"leser@em.uni-frankfurt.de", unilink: "https://www.uni-frankfurt.de/55946073/Dr__phil__Christoph_Leser", address: {l1:"Goethe-Universität", l2:"Theodor-W.-Adorno-Platz 6", l3: "60323 Frankfurt am Main"}, webaddress:"https://www.uni-frankfurt.de/55943668/Professionalisiertes_Fallverstehen_und_Beratung", webtitle:"www.uni-frankfurt.de - Professionalisiertes Fallverstehen und Beratung"},
				{heading:"am DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation:", name: "Dr. Thomas Lösch", designation: "Arbeitsbereich Forschungsdaten Bildung",telephone:"+49 69 24708-520", email:"loesch@dipf.de", unilink: "https://www.dipf.de/de/institut/personen/loesch-thomas ", address:{l1:"DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation ", l2:"Rostocker Straße 6 ", l3: "60323 Frankfurt am Main"}, webaddress:"https://www.dipf.de/de", webtitle:"www.dipf.de - Leibniz-Institut für Bildungsforschung und Bildungsinformation"}
			]
		}
	},

}
</script>
<style scoped>
.fa{
	padding-right:5px;
}
a{
	opacity: 100;
}
a.member-link{
	cursor: pointer;
}
img.thumb-post-img{
	background-color: grey;
	height:170px;
	width:110px;
	/* border-radius: 10px; */
	padding-top:0px;
}
img{
	width:100px;
	height:300px;
}
a.member-link{
	padding-bottom: 5px;
	font-size: 14px;
}
.team-member{
	position: relative;
	height:250px;
	padding:8px;
	margin-bottom:15px;
	border-radius: 15px;
	/*
	background-color:#fff;
	box-shadow: 9px 10px 23px 0px rgba(0, 0, 0, 0.651);
	-webkit-box-shadow: 9px 10px 23px 0px rgba(0, 0, 0, 0.651);
	-moz-box-shadow: 9px 10px 23px 0px rgbargba(0, 0, 0, 0.651); */
}
.member-name {
    font-size: 22px;
    font-weight: 500;
    margin-top: 8px;
    display: block;
		padding-left:5px;
}
.member-role {
    position: absolute;
    bottom: 0;
    left:0;
		width:100%;
    background-color: transparent;
    padding: 5px 5px;
    font-size: 12px;
		line-height:12px;
		color:black;
}

.contact-card-wrap{
	background-color:#fff;
	height:260px;
	padding:3px;
	margin-bottom:15px;
	/* border-radius: 15px; */
	box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
	-webkit-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
	-moz-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
}
.service-icon-wrap{
	display: block;
	text-align: left;
	padding-left: 15px;
}
svg{
	padding:0px;
	margin:0px;
}
div#user-icon{
	display: inline-block;
	padding-top: 25px;
}
div#user-name{
	display: inline-block;
	margin-top:0px;
	padding-left:5px;
}
.w-5 {
    width: 5rem;
}
.py-1 {
    padding-top: 0rem;
    padding-bottom: 0rem;
}
.-mt-1 {
    margin-top: -0.55rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
/* path { fill: #42b983; } */
.fill-current {
    fill: currentColor;
}

h3.contact-name {
	text-align: right;
  font-size: 18px;
  font-weight: normal;
  display: inline-block;
	padding:0px;
	margin-bottom: 5px;
}
.service-cnt-wrap{
	display: block;
}

.ml-1 {
    margin-left: 0.25rem;
}
.text-green-400, .text-green-600{
    --text-opacity: 1;
    color: #42b983;
    color: rgba(129,215,151,var(--text-opacity));
}
.text-purple-400, .text-purple-600 {
    --text-opacity: 1;
    color: #6670EB;
    color: rgb(102,112,235,var(--text-opacity));
}
.text-gray-400, .text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113,128,150,var(--text-opacity));
}
.text-dark-600 {
    --text-opacity: 1;
    color: #1a1d22;

}
.text-xs {
    font-size: .75rem;
}
.text-ms {
	font-size: .80rem;
}
.text-sl {
	font-size: .85rem;
}
.text-ml {
	font-size: 1rem;
}
.text-xl {
	font-size: 1.5rem;
}
.font-light {
    font-weight: 300;
}
div.role{
	text-align: left;
	padding-left: 20px;
}
div.text-email-link, div.text-fa-user-o{
	height:20px;

}
span.text-email{
	padding-left:5px;
}

div.address-text, span.address-link{
	display: block;
	text-align: left;
	padding-left: 20px;
	line-height: 20px;
}
div.address-box{
	padding-top:15px;
}
div.text-wrap{
	padding-top:10px;
}
span.address-link{
	padding-top:5px;
}

@media (max-width: 660px)
{
	div.role{
		font-size: x-small;
		line-height: 15px;
		color:black;
		padding-left:12px;
		padding-bottom: 5px;

	}
	div.text-wrap{
		padding-top:0px;
	}
	div.address-text{
		font-size: x-small;
		line-height: 15px;
		padding-top: 0px;
		padding-left:12px;

	}
	span.address-link{
		font-size: x-small;
		line-height: 10px;
		padding-top: 5px;
		padding-left:12px;

	}
	div.contact-details{
		font-size: x-small;
		line-height: 15px;

	}
	 p.dark-text{
	font-size: 14px;
  word-spacing: 0.2px;
  }
}
</style>