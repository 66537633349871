<template>
  <div class="contact">
   <div id="mobile_menu" :class="[collapsed ? '' : 'visible-sm visible-xs']" class="responsive_menu"  >

        <ul class="main_menu">
            <li><a href="/">Startseite</a></li>
						<li><a href="/veranstaltungen">Veranstaltungen</a></li>

            		<li class="active"><a href="contact">Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
                <li><a href="datenschutz">Datenschutz</a></li>

        </ul> <!-- /.main_menu -->
    </div> <!-- /.responsive_menu -->

  <header class="site-header clearfix">
    <div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="logo">
            <!-- <div class="pull-leftqualibi_logo"> -->
              <a href="/"><img class="pull-left qualibi_logo_img" src="../../src/assets/logo.png"></a>
            <!-- </div> -->
					</div>	<!-- /.logo -->
					<div class="main-navigation pull-right">
						<nav class="main-nav visible-md visible-lg">
							<ul class="sf-menu">
								<li><a href="/">Startseite</a></li>
								<li><a href="/veranstaltungen">Veranstaltungen</a></li>

                <li  class="active"><a href="contact">Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
								<li><a href="datenschutz">Datenschutz</a></li>
							</ul> <!-- /.sf-menu -->
						</nav> <!-- /.main-nav -->

							<!-- This one in here is responsive menu for tablet and mobiles -->
					    <div class="responsive-navigation visible-sm visible-xs">
					        <a @click=" collapsed = !collapsed" href="#" class="menu-toggle-btn">
                      <i :class="[collapsed ? 'fa-bars' : 'fa-times fa-lg', 'fa']"></i>
					        </a>
					    </div> <!-- /responsive_navigation -->

					</div> <!-- /.main-navigation -->

				</div> <!-- /.col-md-12 -->

			</div> <!-- /.row -->

		</div> <!-- /.container -->
	</header> <!-- /.site-header -->
  <section  id="contact-heading" class="cta clearfix">
		<div class="container">
		<div class="row">
				<div class="col-md-12">
						<div class="section-header">
						<h1 class="h1-headings purple-text">Kontakt</h1>
					</div> <!-- /.section-header -->
				</div>
			</div>
		</div>
	</section>
<section id="contact-main">
	<div class="container">
		<div class="row">
			<div class="col-md-12 contact-wrapper">
				<p> Das Angebot QualiBi | Daten der qualitativen Bildungsforschung wird getragen vom DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation und vom Fachbereich Erziehungswissenschaft an der Goethe-Universität Frankfurt.</p>
					<div class="content">
						<h4>E-Mail-Adresse des Projekts:</h4>
						<h4><a href="mailto:kontakt@qualibi.net">kontakt@qualibi.net</a></h4>
					</div>
								<div class="contact-form">
											<div class="kontakt">
												<div class="container">
													<div class="row">
															<div class="col-md-12">
													</div> <!-- /.col-md-12 -->
													<div v-for="(person,index) in ContactDetails" :key='index'>
														<div class="col-md-6 col-sm-6">

															<h6>Kontakt {{person.heading}}</h6>

													<div class="service-box-wrap contact-card-wrap">
														<div class="service-icon-wrap">
															<i class="fa fa-user fa-2x"></i>

															<div id="user-name">
														<h3 class="contact-name">{{person.name}}</h3>
														</div>
														</div> <!-- /.service-icon-wrap -->

														<div class="service-cnt-wrap">
															<div class="role ml-1 text-xl font-light dark-text">
																<span>{{person.designation}}</span>
																</div>
															<div class="text-wrap">
																<div class="contact-details text-xl  dark-text ">
																<div class="text-email-link"><a href="mailto:leser@em.uni-frankfurt.de"><i class="fa fa-envelope-o text-purple-600"></i>{{person.email}}</a></div>
																<div class="text-fa-user-o" ><a target="_blank"  :href="`${person.unilink}`"><i class="fa fa-user text-purple-600"></i>Profil von {{person.name}}</a></div>
																<span class="telephone-number  text-green-400"><i class="fa fa-phone  text-purple-400"></i> {{person.telephone}}</span>
															</div>

															<div class="address-box  dark-text text-xl">
																<div class="address-text ">{{person.address.l1}}<br>{{person.address.l2}}<br>{{person.address.l3}}</div>
																<span class="address-link text-ml"> <a  target="_blank"  :href="`${person.webaddress}`"><i class="fa fa-external-link text-purple-600"></i>{{person.webtitle}}</a></span>
															</div>
														</div>

														</div> <!-- /.service-cnt-wrap -->
													</div> <!-- /.service-box-wrap -->

														</div> <!-- /.col-md-6 -->
													</div>

													</div>
												</div>
											</div>

								</div> <!-- /.contact-form -->
						</div> <!-- /.col-md-12 -->
				</div> <!-- /.row -->
			</div> <!-- /.col-md-8 -->
</section>

	<footer class="site-footer footer-design">
    <div class="container">
			<div class="row">
				<div class="col-md-12">
					<nav class="footer-nav clearfix">
						<ul class="footer-menu">
								<li><a href="/">Startseite</a></li>
								<li><a href="/veranstaltungen">Veranstaltungen</a></li>
                <li class="active"><a href="contact">Kontakt</a></li>
                <li><a href="impressum">Impressum</a></li>
								<li><a href="datenschutz">Datenschutz</a></li>
						</ul> <!-- /.footer-menu -->
					</nav> <!-- /.footer-nav -->
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
			<div class="row">
				<div class="col-md-12">
					<p class="copyright-text">Copyright &copy; DIPF</p>
				</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->
		</div> <!-- /.container -->
	</footer> <!-- /.site-footer -->
	<ScrollTopArrow></ScrollTopArrow>
  </div>
</template>

<script>
import ScrollTopArrow from '@/components/ScrollTopArrow'
export default {
  name: 'Contact',
	components: {
    ScrollTopArrow
  },
	data(){
    return {
			collapsed: true,
			ContactDetails: [
				{heading:"an der Goethe-Universität:", name: "Dr. Christoph Leser", designation: "Fachbereich Erziehungswissenschaften ", telephone:"+49 (0)69 798 36723", email:"leser@em.uni-frankfurt.de", unilink: "https://www.uni-frankfurt.de/55946073/Dr__phil__Christoph_Leser", address: {l1:"Goethe-Universität", l2:"Theodor-W.-Adorno-Platz 6", l3: "60323 Frankfurt am Main"}, webaddress:"https://www.uni-frankfurt.de/55943668/Professionalisiertes_Fallverstehen_und_Beratung", webtitle:"www.uni-frankfurt.de - Professionalisiertes Fallverstehen und Beratung"},
				{heading:"am DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation:", name: "Dr. Thomas Lösch", designation: "Arbeitsbereich Forschungsdaten Bildung",telephone:"+49 69 24708-520", email:"loesch@dipf.de", unilink: "https://www.dipf.de/de/institut/personen/loesch-thomas ", address:{l1:"DIPF | Leibniz-Institut für Bildungsforschung und Bildungsinformation ", l2:"Rostocker Straße 6 ", l3: "60323 Frankfurt am Main"}, webaddress:"https://www.dipf.de/de", webtitle:"www.dipf.de - Leibniz-Institut für Bildungsforschung und Bildungsinformation"}
			]
		}
	}

}
</script>
<style scoped>
.contact-wrapper{
	text-align: left;
	margin-top:20px;
}
.fa{
	padding-right:5px;
}
.contact-card-wrap{
	background-color:#fff;
	height:260px;
	padding:3px;
	margin-bottom:15px;
	/* border-radius: 15px; */
	box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
	-webkit-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
	-moz-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
}
.service-icon-wrap{
	display: block;
	text-align: left;
	padding-left: 15px;
}
svg{
	padding:0px;
	margin:0px;
}
div#user-icon{
	display: inline-block;
	padding-top: 25px;
}
div#user-name{
	display: inline-block;
	margin-top:0px;
	padding-left:5px;
}
.w-5 {
    width: 5rem;
}
.py-1 {
    padding-top: 0rem;
    padding-bottom: 0rem;
}
.-mt-1 {
    margin-top: -0.55rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
/* path { fill: #42b983; } */
.fill-current {
    fill: currentColor;
}

h3.contact-name {
	text-align: right;
  font-size: 18px;
  font-weight: normal;
  display: inline-block;
	padding:0px;
	margin-bottom: 5px;
}
.service-cnt-wrap{
	display: block;
}

.ml-1 {
    margin-left: 0.25rem;
}
.text-green-400, .text-green-600{
    --text-opacity: 1;
    color: #42b983;
    color: rgba(129,215,151,var(--text-opacity));
}
.text-purple-400, .text-purple-600 {
    --text-opacity: 1;
    color: #6670EB;
    color: rgb(102,112,235,var(--text-opacity));
}
.text-gray-400, .text-gray-600 {
    --text-opacity: 1;
    color: #718096;
    color: rgba(113,128,150,var(--text-opacity));
}
.text-dark-600 {
    --text-opacity: 1;
    color: #1a1d22;

}
.text-xs {
    font-size: .75rem;
}
.text-ms {
	font-size: .80rem;
}
.text-sl {
	font-size: .85rem;
}
.text-ml {
	font-size: 1rem;
}
.text-xl {
	font-size: 1.5rem;
}
.font-light {
    font-weight: 300;
}
div.role{
	text-align: left;
	padding-left: 20px;
}
div.text-email-link, div.text-fa-user-o{
	height:20px;

}
span.text-email{
	padding-left:5px;
}

div.address-text, span.address-link{
	display: block;
	text-align: left;
	padding-left: 20px;
	line-height: 20px;
}
div.address-box{
	padding-top:15px;
}
div.text-wrap{
	padding-top:10px;
}
span.address-link{
	padding-top:5px;
}
#contact-heading{
  margin-top:10vh;
}
@media (max-width: 660px)
{
	div.role{
		font-size: x-small;
		line-height: 15px;
		color:black;
		padding-left:12px;
		padding-bottom: 5px;

	}
	div.text-wrap{
		padding-top:0px;
	}
	div.address-text{
		font-size: x-small;
		line-height: 15px;
		padding-top: 0px;
		padding-left:12px;

	}
	span.address-link{
		font-size: x-small;
		line-height: 10px;
		padding-top: 5px;
		padding-left:12px;

	}
	div.contact-details{
		font-size: x-small;
		line-height: 15px;

	}
}
</style>
