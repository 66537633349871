import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import Veranstaltungen from '../components/Veranstaltungen.vue'

import Contact from '../components/Contact.vue'
import Impressum from '../components/Impressum.vue'
import DatenSchutz from '../components/DatenSchutz.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/veranstaltungen',
    name: 'veranstaltungen',
    component: Veranstaltungen
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'DatenSchutz',
    component: DatenSchutz
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {

    if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
  },
  routes
})

export default router
