<template>
  <ScrollTopComponent>
      <a class="btn btn-light">
        <i class="fa fa-arrow-up fa-2x"></i>
      </a>
  </ScrollTopComponent>
</template>

<script>
import ScrollTopComponent from '@/components/ScrollTopComponent'
export default {
  name: 'ScrollTopArrow',
  components: {
    ScrollTopComponent
  }
}
</script>

<style>
.btn {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.575);
    padding-top: 27px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}
</style>