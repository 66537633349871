<template>
<div>
  <section id="daten-nutzen-heading" class="cta clearfix">
		<div class="container">
		<div class="row">
				<div class="col-md-12">
						<div class="section-header-heading">
						<h1 class="h1-headings purple-text">Daten finden und teilen</h1>

					</div> <!-- /.section-header -->
				</div>
			</div>
		</div>
	</section>
  <section id="daten-nutzen">
    <div class="container">
			<div class="row">
				<div class="col-md-12 text-box-md-12">

    <p class="dark-text">
      QualiBi wird eine integrierte Suche und Bereitstellung bisher separat vorliegender Datenbestände bieten sowie einen eigenen Datenbestand aufbauen.
      Die qualitativen Daten des Forschungsdatenzentrums (FDZ) Bildung sowie des Archivs für pädagogische Kasuistik (ApaeK) stellen dabei die Basis dar.
      Sukzessive können weitere Archive angebunden werden, um auch deren Bestände über die Plattform durchsuchen zu können.
      Darüber hinaus besteht die Möglichkeit, Daten aus aktuellen oder vergangenen Forschungsprojekten über QualiBi zur Nachnutzung verfügbar zu machen.
      Die Daten werden sorgsam aufbereitet und unter einem, auf die Spezifik sensibler qualitativer Daten ausgerichteten Zugangskonzept bereitgestellt.
      Bei Interesse nehmen Sie gerne Kontakt mit uns auf.
    </p>
    <p class="dark-text">
      Bis die Datensuche über QualiBi zur Verfügung steht, besuchen Sie gerne die Seiten von FDZ Bildung und ApaeK.
    </p>
    <div class="event-list-container ">
    <ul class="event-list">
      <li class="event-listitem">
        <div class="listitem ">
            <div class="event-content">
                <div class="title font-special">
                    <div class="event-h3">
                      Datensuche im FDZ Bildung:
                    </div>
                </div>

                <div class="detail font-primary">
                  <div class="summary ">
                    <a target="_blank"  href="https://www.fdz-bildung.de/datenarchiv.php"><i class="fa fa-external-link text-purple-600"></i>fdz-bildung.de/datenarchiv</a>
                  </div>
                </div>
              </div>
          </div>
      </li>
      <li class="event-listitem">
        <div class="listitem ">
            <div class="event-content">
                <div class="title font-special">
                    <div class="event-h3">
                      Datensuche im Archiv für pädagogische Kasuistik (ApaeK):
                    </div>
                </div>

                <div class="detail font-primary">
                  <div class="summary ">
                      <a target="_blank"  href="https://archiv.apaek.uni-frankfurt.de/"><i class="fa fa-external-link text-purple-600"></i>archiv.apaek.uni-frankfurt</a>
                  </div>
                </div>
              </div>
          </div>
      </li>
    </ul>
    </div>
    <p class="dark-text">
      QualiBi arbeitet eng mit dem Verbund Forschungsdaten Bildung (VerbundFDB) und dem Konsortium für Sozial-,
      Verhaltens-, Bildungs- und Wirtschaftswissenschaften (KonsortSWD) zusammen.
      Deren Suche können Sie nutzen, um Daten auch außerhalb der qualitativen Bildungsforschung zu finden
    </p>
    <div class="event-list-container ">
    <ul class="event-list">
      <li class="event-listitem">
        <div class="listitem ">
            <div class="event-content">
                <div class="title font-special">
                    <div class="event-h3">
                      Datensuche des VerbundFDB:
                    </div>
                </div>

                <div class="detail font-primary">
                  <div class="summary ">
                    <a target="_blank" href="https://www.forschungsdaten-bildung.de/suchen-finden"><i class="fa fa-external-link text-purple-600"></i>forschungsdaten-bildung.de/suchen-finden</a>
                  </div>
                </div>
              </div>
          </div>
      </li>
      <li class="event-listitem">
        <div class="listitem ">
            <div class="event-content">
                <div class="title font-special">
                    <div class="event-h3">
                      Datensuche des KonsortSWD:
                    </div>
                </div>

                <div class="detail font-primary">
                  <div class="summary ">
                    <a target="_blank" href="https://www.konsortswd.de/datenzentren/datensuche-in-den-fdz/"><i class="fa fa-external-link text-purple-600"></i>konsortswd.de/datenzentren/datensuche-in-den-fdz</a>
                  </div>
                </div>
              </div>
          </div>
      </li>
    </ul>
    </div>


          </div> <!-- /.section-header -->
			</div> <!-- /.col-md-12 -->
		</div> <!-- /.row -->
  </section>
  </div>
</template>
<script>
export default {
  name: 'DatenFinden',
  props: {

  }
}
</script>
<style scoped>
.fa{
  padding-left: 5px;
  padding-right: 2px;
}
a{
  font-weight: 500;
}
div.event-list-container {
  padding:20px;
  margin-bottom:20px;
  background-color: white;
  box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
  -webkit-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
  -moz-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
}
ul#event-list, li.event-listitem{
  list-style: none;

}
ul#event-list{
  padding-inline-start: 20px;
}
.event-listitem{
  margin:0px;
  padding:0px;
}
.event-content {
    width: 100%;
    margin-left: 0px;
    text-align: left;
    padding-left:0px;
}
.event-h3{
    margin: 0 0 5px 0;
    display: inline;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 25px;
    line-height: 27px;
    color: #6670EB;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}
.summary{
   font-style: none;
  font-size: 15px;
  font-family: "Inter", sans-serif;
}
@media (max-width: 660px){
  .event-h3{
    font-size: 15px;
    line-height: 20px;
  }
  div.summary a{
    font-size: 12px;;
  }
  p.dark-text{
	font-size: 14px;
  word-spacing: 0.2px;
  }
}

</style>