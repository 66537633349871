<template>
<div>
    <section id="austausch-vernetzung-heading" class="cta clearfix">
		<div class="container">
		<div class="row">
				<div class="col-md-12">
						<div class="section-header-heading">
						<h1 class="h1-headings purple-text">Austausch und Vernetzung</h1>
					</div> <!-- /.section-header -->
				</div>
			</div>
		</div>
	</section>
  <section id="austausch-vernetzung">
    <div class="container">
			<div class="row">
				<div class="col-md-12 ">
          <h1 class="section-title">Werkstattgespräche</h1>
              <p class="dark-text">
                Im Rahmen von QualiBi widmen wir uns Fragen einer angemessenen Aufarbeitung qualitativer Daten im Licht ihrer je spezifischen Besonderheit und Provenienz.
                Die Übergabe der Daten, ihre Dokumentation und Kontextualisierung, die Regulierung des Zugangs zu ihnen erfolgen abgestimmt mit den Forscher*innen, die sie erhoben haben.
                Die Werkstattgespräche zielen darauf, die mit der Bereitstellung und Archivierung verbundenen Fragen von Kolleg*innen an deren konkretem Material zu erörtern.
                Wir laden dazu Kolleg*innen ein, die qualitative Daten aus einem laufenden oder abgeschlossenen Forschungsprojekt archivieren und für weitere Forschung oder für die Lehre bereitstellen wollen.
              </p>
              <p class="dark-text">
                Dafür vereinbaren wir mit Ihnen gerne einen individuellen Termin, an dem wir Ihre Fragen und Themen entlang der exemplarischen Sichtung des Materials ins Zentrum stellen.
                Neben der Erörterung von Fragen der Aufbereitung der Daten mit dem Ziel einer möglichst passgenauen Kuratierung bieten die Werkstattgespräche zudem die Gelegenheit,
                Ihre methodische und fachliche Expertise auch auf einer grundsätzlicheren Ebene in die Entwicklung der Datenplattform QualiBi einfließen zu lassen.
                Auf diese Weise kann QualiBi auf der Grundlage der Erfahrungen, des breiten Wissens, der unterschiedlichen Bedarfe von Fachvertreter*innen sowie an den je spezifischen Datenmaterialien orientiert ausgestaltet werden.
                Rahmen:
              </p>
              <div class="event-list-container ">
              <ul class="event-list" >
                <li class="event-listitem">
                  <div class="listitem ">
                      <div class="event-content">
                          <div class="title font-special">
                              <div class="event-h3">
                                Wer?
                              </div>
                          </div>
                          <div class="detail font-primary">
                            <div class="summary ">
                                <p>

                                Wissenschaftler*innen aus unterschiedlichen Feldern qualitativer / rekonstruktiver Bildungsforschung, die Daten aus einem eigenen Forschungsprojekt archivieren, teilen und für die Nachnutzung verfügbar machen lassen möchten.
                                </p>
                            </div>
                          </div>

                          <div class="title font-special">
                              <div class="event-h3">
                                Wann?
                              </div>
                          </div>
                          <div class="detail font-primary">
                            <div class="summary ">
                                <p>
                                  Individuelle Terminvereinbarung auf Anfrage jederzeit möglich
                                </p>
                            </div>
                          </div>
                          <div class="title font-special">
                              <div class="event-h3">
                                Wo?
                              </div>
                          </div>
                          <div class="detail font-primary">
                            <div class="summary ">
                                <p>
                                  Die Forschungswerkstatt kann sowohl vor Ort an der Goethe-Universität Frankfurt als auch online stattfinden.
                                  (Aufgrund der aktuellen pandemischen Lage sind derzeit ausschließlich Online-Termine möglich.)
                                </p>
                            </div>
                          </div>
                          <div class="title font-special">
                              <div class="event-h3">
                                Was ist das Ziel?
                              </div>
                          </div>
                          <div class="detail font-primary">
                            <div class="summary ">
                                <p>
                                  Wechselseitige Beratung und Austausch zur Archivierung spezifischer Datentypen,
                                  Erörterung forschungsethischer und datenschutzrechtlicher Fragen und das Ausloten weitergehender Kooperationsmöglichkeiten.
                                </p>
                            </div>
                          </div>
                          <div class="title font-special">
                              <div class="event-h3">
                                Kontakt:
                              </div>
                          </div>
                          <div class="detail font-primary">
                            <div class="summary ">
                                <p>
                                Dr. Christoph Leser, Projektkoordination QualiBi.<br>
                                E-Mail:<a href="mailto:leser@em.uni-frankfurt.de"><i class="fa fa-envelope-o text-purple-600"></i>leser@em.uni-frankfurt.de</a>
                              </p>
                            </div>
                          </div>
                        </div>
                    </div>
                </li>
              </ul>
              </div>
    </div>

      <div class="col-md-12">
						<h1 class="section-title">Forschungswerkstatt</h1>

          <p class="dark-text">
            Wir führen im Rahmen von QualiBi eine Forschungswerkstatt durch, in die empirisches Material für eine Analyse mit bereits vorhandenen qualitativen Daten eingebracht werden kann. Neben den Forschungsinteressen des jeweiligen Projekts sollen dabei methodische und methodologische Fragen und Probleme im Fokus stehen, die sich im Horizont eines sekundäranalytischen Vorgehens stellen. Die Forschungswerkstatt ist für alle methodischen Zugänge qualitativer/rekonstruktiver Bildungsforschung offen, wobei die methodische Expertise von den Teilnehmenden leitend in der jeweiligen Sitzung wird. Im Fokus stehen Problematiken, die bei der Analyse vorhandener Daten mit einem je spezifischen methodischen Zugriff auftreten können. In der forschenden Arbeit am Material sollen so methodologische und methodische Fragen ausgeschärft und bearbeitet werden. Die Forschungswerkstatt ist geöffnet für weitere Interessierte. Die Arbeit in der Forschungswerkstatt kann sich auch über mehrere Termine in einer kontinuierlichen Gruppe erstrecken, wobei bei den Terminen jeweils ein Forschungsprojekt im Zentrum steht. Wir laden Interessierte herzlich ein, ihr Projekt in die Forschungswerkstatt einzubringen.
            Rahmen:
          </p>
<div class="event-list-container ">
    <ul class="event-list" >
      <li class="event-listitem">
        <div class="listitem ">
            <div class="event-content">
                <div class="title font-special">
                    <div class="event-h3">
                      Wer?
                    </div>
                </div>

                <div class="detail font-primary">
                  <div class="summary ">
                    <p>
                    Wissenschaftler*innen aus unterschiedlichen Teilgebieten der qualitativen/rekonstruktiven Bildungsforschung, die in ihrem Forschungsprojekt (auch) auf Forschungsdaten aus vorgängigen eigenen Projekten oder archivierten Daten aus Projekten Dritter zurückgreifen.
                    </p>
                    </div>
                </div>
                <div class="title font-special">
                    <div class="event-h3">
                      Wann?
                    </div>
                </div>
                <div class="detail font-primary">
                  <div class="summary ">
                      <p>
                        Individuelle Terminvereinbarung auf Anfrage jederzeit möglich; bei Interesse für eine kontinuierliche Gruppe etwa mit einem spezifisch methodologisch/methodischen Fokus kann diese ebenfalls über uns organisiert werden.
                      </p>
                  </div>
                </div>
                <div class="title font-special">
                    <div class="event-h3">
                      Wo?
                    </div>
                </div>
                <div class="detail font-primary">
                  <div class="summary ">
                      <p>
                        Die Forschungswerkstatt kann sowohl vor Ort an der Goethe-Universität Frankfurt als auch online stattfinden. (Aufgrund der aktuellen pandemischen Lage sind derzeit ausschließlich Online-Termine möglich.)
                      </p>
                  </div>
                </div>
                <div class="title font-special">
                    <div class="event-h3">
                      Was ist das Ziel?
                    </div>
                </div>
                <div class="detail font-primary">
                  <div class="summary ">
                      <p>
                        Bearbeitung methodologisch-methodischer und forschungsethischer Fragen, die sich im Rahmen qualitativer Sekundäranalysen stellen, Arbeit am konkreten empirischen Material sowie Vernetzungsmöglichkeiten für Forschende.
                      </p>
                  </div>
                </div>
                <div class="title font-special">
                    <div class="event-h3">
                      Kontakt:
                    </div>
                </div>
                <div class="detail font-primary">
                  <div class="summary ">
                    <p>
                      Sinje Brinkmann, wissenschaftliche Mitarbeiterin im Projekt QualiBi.<br>
                      E-Mail:<a href="mailto:brinkmann@em.uni-frankfurt.de"><i class="fa fa-envelope-o text-purple-600"></i>brinkmann@em.uni-frankfurt.de</a>
                    </p>
                  </div>
                </div>
              </div>
          </div>
      </li>
    </ul>
    </div>
    </div>
			</div> <!-- /.col-md-12 -->
		</div> <!-- /.row -->
  </section>
  </div>
</template>
<script>
export default {
  name: 'AustauschVernetzung',
  props: {

  }
}
</script>
<style scoped>
.fa{
  padding-left: 5px;
  padding-right: 2px;
}
div#austausch-vernetzung{
  padding:30px;
  margin:30px;
}
div.event-list-container {
  padding:20px;
  background-color: white;
  box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
  -webkit-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
  -moz-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
}
ul#event-list, li.event-listitem{
  list-style: none;


}
/* ul#event-list{
  padding-inline-start: 20px;
} */

ul.event-list{
   padding:0px;
  }

.event-listitem{
  margin:0px;
  padding:0px;
}
.event-content {
    width: 100%;
    margin-left: 0px;
    text-align: left;
    padding-left:0px;
}
.event-h3{
    margin: 0 0 5px 0;
    display: inline;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 25px;
    line-height: 27px;
    color: #6670EB;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}
.summary{
  font-style: none;
  font-size: 15px;
  font-family: "Inter", sans-serif;


}
@media (max-width: 660px)
{

  p.dark-text{
	font-size: 14px;
  word-spacing: 0.2px;
  }
  ul.event-list{
    padding-left:0px;
  }
  .event-h3{
    font-size: 15px;
    line-height: 20px;
  }
  div.summary a{
    font-size: 12px;;
  }
  div.summary p{
    font-size: 12px;;
  }
}
</style>