<template>
  <div>

<section id="home-head">

		    <div class="container home-intro-content">
		        <div class="row">
		        	<div class="col-md-12">
								<div class="contain-main-heading">
		        			<h1 class="h1-headings-main">Willkommen bei QualiBi <br><br> der Forschungsdatenplattform für die qualitative Bildungsforschung</h1>
		        		</div>

		        	</div>
		        </div>
		    <!-- </div> -->
	    </div>
	</section>

<section class="cta clearfix">
		<div class="container">
		<div class="row">
				<div class="col-md-12">
					<h4 class="cta-title">
            <strong></strong>
          </h4>
							<p class="purple-text big-text">
							QualiBi ist eine Plattform, auf der Sie zukünftig Daten der qualitativen Bildungsforschung zentral
							finden und teilen können. Weiterhin finden Sie Informationen rund um Forschungsdaten sowie Möglichkeiten,
							um in Austausch zu treten.
					</p>
				</div>
			</div>
		</div>
	</section>



	<section class="services dimension">
		<div class="container">
			<div class="row">
				<div class="col-md-12 text-box-md-12">

					<p class="dark-text">
						Die Plattform befindet sich derzeit im Aufbau.
						Auf dieser Seite finden Sie eine Zusammenstellung von Informationen und Veranstaltungen rund um
						Forschungsdaten der qualitativen Bildungsforschung.
					</p>
					</div> <!-- /.col-md-12 -->
			</div> <!-- /.row -->

			<div class="row">
			<div v-for="(card,index) in cardData" :key='index'>
				<div class="col-md-3 col-sm-3">
					<div class="service-box-wrap card-wrap">
						<div class="service-icon-wrap">
							<i :class="`${card.icon}`" @click="scroll(`${card.ref}`)"><h3>{{card.title}}</h3></i>
						</div> <!-- /.service-icon-wrap -->
						<div class="service-cnt-wrap">
							<!-- <h3  @click="scroll(`${card.ref}`)">{{card.title}}</h3> -->

						<div class="text-wrap">
							<div class="card-text">{{card.description}}</div>
						</div>
						</div> <!-- /.service-cnt-wrap -->
					</div> <!-- /.service-box-wrap -->
				</div> <!-- /.col-md-3 -->
			</div>

			</div>
			<div class="row">
				<p></p>
			</div>


		</div> <!-- /.container -->
	</section> <!-- /.services -->

  </div>
</template>

<script>
export default {
  name: 'HeadContent',

	 data(){
    return {
/* 			refName:"", */
      cardData: [
					{title: "Über QualiBi", ref:"about-us", icon: "fa fa-info fa-2x", description: "Informationen zu QualiBi und den beteiligten Personen"},
					{title: "Austausch und Vernetzung", ref:"AustauschVernetzung", icon: "fa fa-users fa-2x", description: "Formate zum Austausch über Daten der qualitativen Bildungsforschung und Möglichkeiten der Beteiligung am Aufbau der Plattform"},
					{title: "Veranstaltungen", ref:"Veranstaltungen", icon: "fa fa-calendar fa-2x", description: "Veranstaltungen, die QualiBi organisiert und an denen QualiBi beteiligt ist."},
					{title: "Daten finden", ref:"daten-finden", icon: "fa fa-search fa-2x", description: "Hier können Sie Daten der qualitativen Bildungsforschung bereits jetzt finden"},
				]
		}
	 },
	 methods: {
      scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

	.home-intro-content {
		padding: 8em 1em !important;
		background: rgb(102,112,235);

}

h3.service-title {

  margin: 20px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.service-icon-wrap i:hover{
	color:grey;
	cursor: pointer;
}
.service-icon-wrap h3:hover{
	color:grey;
	cursor: pointer;
}
.service-icon-wrap{
	padding-top:15px;
}
.card-wrap{
	background-color:#fff;
	height:275px;
	padding:3px;
	margin-bottom:15px;
	/* border-radius: 15px; */
	box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
	-webkit-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
	-moz-box-shadow: 9px 10px 23px 0px rgba(0,0,0,0.84);
}
.text-wrap{
padding:5px;
}
div.card-text{
	font-size: 12px;
	color:black;
	text-align: justify;
	overflow-wrap: break-word;
}

@media (max-width: 660px)
{

	.home-intro-content {
    padding: 5em 0 !important;
    text-align: center;
}
	h1.h1-headings-main {
    margin-top:10px;
    display: inline;
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 27px;
    line-height: 30px;
    color: #f8f8fa;
    font-family: "Inter", sans-serif;
    text-shadow: black;
}
h4.cta-title{
    display: block;
    margin-bottom: 10px;
		font-size:20px;
}

.text-wrap{
    padding: 5px;
    text-align: justify;
    overflow-wrap: break-word;
    word-spacing: 0.3em;
		font-size:small;
}

}
</style>
